import React, { useEffect, useState } from 'react';
//import './maps.css'
import Violation from './violation';
import endPoint from '../../../assets/img/ic_checkin.png';
import startPoint from '../../../assets/img/startPoint.png';
import destination from '../../../assets/img/ic_destination.png';
import destination_pin from '../../../assets/img/to-marker.png';
import moment from 'moment'
// import { checkRouteCoverage } from '../../../utils/trikesProductivity';
// import { isProductive } from '../../../utils/trikerPro';
import { prop } from 'ramda';

const { compose, withProps } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Polyline,
  Marker,
  Circle,
  Polygon,
  InfoWindow,
  pure
} = require("react-google-maps");

export const TripMap = compose(
  withProps((props) => {
    return ({
      googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns&v=3.exp&libraries=geometry,drawing,places",
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: props.height ? props.height : '400px' }} />,
      mapElement: <div style={{ height: `100%` }} />,
    })
  }),

  withScriptjs,
  withGoogleMap
)(React.memo((props) => {
  function addViolation(arr, type, company) {
    const array = arr ? arr.map((item, index) => {
      const latlngCords = item.latlngCords;
      const eventTime = moment(item.eventTime).format('LLL')
      const { title } = item;

      return (<Violation key={index} {...{ latlngCords, eventTime, title, type, company }} />);
    }) : [];
    return array;

  }


  const [startingDistance, setStartingDistance] = useState(0);
  const [hoveredData, setHoveredData] = useState(null);
  const [showInfoWindow, setShowInfoWindow] = useState(false);
  const [showTourInfo, setShowTourInfo] = useState(false);
  const [infoData, setInfoData] = useState(null);
  const [address, setAddress] = useState(null);
  const [trikeCoverage, setTrikeCoverage] = useState("");


  useEffect(() => {
  //  if(props?.path && props?.routeFences){
  //     // const coverage = checkRouteCoverage(props?.routeFences.path, props?.path)
  //     const productive = isProductive(props?.path, props?.routeFences.path);
  //     console.log(productive ? "Productive" : "Non-productive");
  //     // console.log("coverage -> ", coverage)
  //     setTrikeCoverage(productive ? "Productive" : "Non-productive")
  // }

    console.log(props?.productiveStatus ? "Productive" : "Non-productive");
    setTrikeCoverage(props?.productiveStatus ? "Productive" : "Non-productive")
  }, [])



  useEffect(() => {
    if (props?.companyPath) {
      setStartingDistance(parseInt(props?.companyPath[0]?.OdoMeter / 1000))
    }
  }, [])

  const handleMouseOver = (e, point) => {
    // const lat = e.latLng.lat();
    // const lng = e.latLng.lng();
    // setHoveredPoint({ lat, lng });
    setHoveredData(point)
    setShowInfoWindow(true)
  };

  const handleMouseOut = () => {
    console.log("mouse out")
    // setHoveredPoint(null);
    setHoveredData(null)
    setShowInfoWindow(false)
  };
  // function addStop(arr) {
  //   const array = arr ? arr.map((item, index) => {

  //     const latlngCords = item.latlng;

  //     const eventTime = moment(item.createdAt).format('LLL');
  //     const title = `Stop # ${(index + 1)} - ${item.duration} minutes`;

  //     return <MarkerWithLabel
  //       key={index}
  //       position={latlngCords}
  //       labelAnchor={window.google.maps.Point(latlngCords.lat, latlngCords.lng)}
  //       labelStyle={{ backgroundColor: "red", fontSize: "8px", color: '#fff', padding: "2px" }}
  //     >
  //       <div>{(index + 1)}</div>
  //     </MarkerWithLabel>
  //     // return (<Violation key={index} type='stop' {...{ latlngCords, eventTime, title,  }} />);
  //   }) : [];
  //   return array;

  // }
  function addStop2(arr) {
    const array = arr ? arr.map((item, index) => {
      const latlngCords = item.latlng;


      //   let shape = {
      //     coords: [25, 25, 25],
      //     type: 'circle'
      // }
      var circle = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: '#662663',
        fillOpacity: 0.5,
        color: '#ffffff',
        // padding:'5px',
        scale: 5,
        strokeColor: '#662663',
        strokeWeight: 7
      };
      return <Marker
        key={index}
        noRedraw={true}
        position={latlngCords}
        label={`${index + 1}`}
        // shape={shape}
        icon={circle}
        zIndex={99999}
      // labelAnchor={window.google.maps.Point(0, 0)}
      // labelStyle={{backgroundColor: "red", fontSize: "8px",color:'#fff', padding: "2px"}}
      >
        {/* <div>{(index+1)}</div> */}
      </Marker>
      // return (<Violation key={index} type='stop' {...{ latlngCords, eventTime, title,  }} />);
    }) : [];
    return array;

  }
  //   function addIdle(arr) {
  //     const array = arr ? arr.map((item, index) => {
  // const [lat,lng]=item.latlng.split(',');
  //       const latlngCords = {lat,lng}

  //       const eventTime = moment(item.idleStartTime).format('LLL');
  //       const title = item.title;
  //       //   let shape = {
  //       //     coords: [25, 25, 25],
  //       //     type: 'circle'
  //       // }
  //       var circle = {
  //         path: window.google.maps.SymbolPath.CIRCLE,
  //         fillColor: 'gray',
  //         fillOpacity: 0.5,
  //         color: '#fff',
  //         // padding:'5px',
  //         scale: 5,
  //         strokeColor: 'red',
  //         strokeWeight: 7
  //       };
  //       return <Marker
  //         key={index}
  //         position={latlngCords}
  //         label={`${index + 1}`}
  //         // shape={shape}
  //         icon={circle}
  //         zIndex={99999}
  //       // labelAnchor={window.google.maps.Point(0, 0)}
  //       // labelStyle={{backgroundColor: "red", fontSize: "8px",color:'#fff', padding: "2px"}}
  //       >
  //         {/* <div>{(index+1)}</div> */}
  //       </Marker>
  //       // return (<Violation key={index} type='stop' {...{ latlngCords, eventTime, title,  }} />);
  //     }) : [];
  //     return array;

  //   }

  let harshAcceleration = addViolation(props.harshAcceleration, "harshAcceleration", props.company)
  let harshTurn = addViolation(props.harshTurn, "harshTurn", props.company)
  let harshBrakes = addViolation(props.harshBrakes, "harshBrakes", props.company)
  let overSpeeding = addViolation(props.overSpeeding, "overSpeeding", props.company)
  let ignitionOn = addViolation(props.ignitionOn, "ignitionOn", props.company)
  let unfastenSeatbelt = addViolation(props.unfastenSeatbelt, "unfastenSeatbelt", props.company)
  let enterCellSite = addViolation(props.enterCellSite, "enterCellSite", props.company);
  // let geofencesArea = props.geofencesArea;
  let stopsList2 = addStop2(props.stops)



  //   const geoFenceList = props.geoFence ? props.geoFence.map((item, index) => {
  //     const latlngCords = item.latlngCords;
  //     const eventTime = moment(item.eventTime).format('LLL')
  //     const { title } = item;
  //     const type = 'geoFence';
  //     return (<Violation key={index} {...{ latlngCords, eventTime, title, type }} />);
  //   }) : [];

  // const geofencesArea = props.geofencesArea[0] && props.geofencesArea[0] != null
  //   ? props.geofencesArea.map((item, index) => {
  //     const center = item.center;
  //     const radius = item.radius;

  //     console.log("Mapping geofencesArea item:", item);
  //     console.log("Mapping geofencesArea center:", center);
  //     console.log("Mapping geofencesArea radius:", center);

  //     return (
  //       <Circle
  //         key={index}
  //         center={center}
  //         draggable={false}
  //         editable={false}
  //         radius={radius}
  //         options={{
  //           strokeColor: '#00994C',
  //           fillColor: '#00994C',
  //           fillOpacity: 0.42,
  //         }}
  //       />
  //     );
  //   })
  //   : [];


  const idleList = props.idle ? props.idle.map((item, index) => {
    const [lat, lng] = item.latlng.split(',');
    const latlngCords = { lat: parseFloat(lat), lng: parseFloat(lng) };

    const idleStartTime = moment(item.idleStartTime).format('LLL')
    let { title } = item;
    const type = 'IDLE';
    title = title + " For " + moment(item.idleEndTime).diff(moment(item.idleStartTime), 'minutes') + " Min"
    return (<Violation key={index} {...{ latlngCords, eventTime: idleStartTime, title, type }} />);
  }) : [];
  const [isPioneer, setIsPioneer] = useState(false)
  useEffect(() => {
    if (localStorage.companyInfo) {
      let companyInfo = JSON.parse(localStorage.companyInfo);
      if (companyInfo.companyId === "6368c4c014fdb5326e454b8f") {
        setIsPioneer(true);
      }
    }
  }, [])
  function radiusToZoom() {

    let radius = props.distance / 2;
    radius = radius || 5;
    return Math.round(14 - Math.log(radius) / Math.LN2);
  }
  const zoom = 7;  //parseInt(radiusToZoom(), 10);

  // useEffect(() => {
  //   {

  //     if (props.infoWindowContent?.latlng?.lat && props.infoWindowContent?.latlng?.lng) {
  //       const geocoder = new window.google.maps.Geocoder();
  //       const latlng = {
  //         lat: parseFloat(props.infoWindowContent?.latlng?.lat),
  //         lng: parseFloat(props.infoWindowContent?.latlng?.lng),
  //       };

  //       geocoder.geocode({ location: latlng }, (results, status) => {
  //         if (status === "OK") {
  //           if (results[0]) {
  //             setAddress(results[0].formatted_address);
  //           } else {
  //             console.error("No results found");
  //           }
  //         } else {
  //           console.error("Geocoder failed due to: " + status);
  //         }
  //       });
  //     }
  //   }
  // }, [props.infoWindowContent]);


  return (
    <div className='my-5'>

   { trikeCoverage && <p className={trikeCoverage && trikeCoverage === "Productive" ? "coverage-green" : "coverage-orange"}>{trikeCoverage}</p>}
      <GoogleMap
        defaultZoom={zoom}
        zoom={props.playbackStatus ? 14 : zoom}
        defaultCenter={props.position ? props.position : props.path[0]}
        center={props.position ? props.position : props.path[0]}
      >
        <Polyline
          path={props.path}
          options={{ strokeColor: "#03ABCE", strokeWeight: 5 }}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        />
        {props?.companyPath?.map((point, index) => (
          <Polyline
            key={index}
            path={[point.latlng, point.latlng]}
            options={{ strokeOpacity: 0, clickable: true, strokeWeight: 5 }}
            onMouseOver={(e) => handleMouseOver(e, point)}
          />
        ))}

        {props.routeFences && props.routeFences.path && props.routeFences.path.length > 0 && (
          <Polyline
            path={props.routeFences.path.map(coord => ({
              lat: coord[1],
              lng: coord[0],
            }))}
            options={{
              strokeColor: "#A9A9A9",
              strokeWeight: 8,
              strokeOpacity: 0.8,
            }}
          />
        )}


        {props?.infoWindowContent && props?.infoWindowContent?.distance > 0 ? (
          <InfoWindow position={props?.infoWindowContent.latlng} onCloseClick={() => setHoveredData(null)}>
            <div className="custom-info-window">
              <p>Lat: {props?.infoWindowContent.latlng.lat}</p>
              <p>Lng: {props?.infoWindowContent.latlng.lng}</p>
              <p>Distance Covered: {parseFloat(props?.infoWindowContent.distance).toFixed(2) || "0"} KM</p>
              <p>Speed: {props?.infoWindowContent.speed}</p>
              <p>Angle: {props?.infoWindowContent.angle}</p>
              {/* <p>Location: {address || "Fetching address..."}</p> */}
              <p>Time: {props?.infoWindowContent.time}</p>
              {/* <p>Status: {hoveredData.status}</p> */}
            </div>
          </InfoWindow>
        ) : <span></span>}

        {showInfoWindow && hoveredData ? (
          <InfoWindow position={hoveredData?.latlng} onCloseClick={() => setHoveredData(null)}>
            <div className="custom-info-window">
              <p>Lat: {hoveredData?.latlng.lat}</p>
              <p>Lng: {hoveredData?.latlng.lng}</p>
              <p>Speed: {hoveredData?.speed}</p>
              <p>Angle: {hoveredData?.angle}</p>
              {/* <p>Location: {address || "Fetching address..."}</p> */}
              <p>Time: {hoveredData?.time}</p>
              {/* <p>Status: {hoveredData.status}</p> */}
            </div>
          </InfoWindow>
        ) : <span></span>}


{showTourInfo && hoveredData?.latlng && infoData ? (
  <InfoWindow
    position={hoveredData.latlng} // Use valid lat/lng object
    onCloseClick={() => {
      setShowTourInfo(false); // Close InfoWindow
      setHoveredData(null); // Clear data
    }}
  >
    <div className="custom-info-window">
      <p><strong>Total Distance:</strong> {infoData.totalDistance} km</p>
      <p><strong>Entry Time:</strong> {infoData.entryTime}</p>
      <p><strong>Exit Time:</strong> {infoData.exitTime}</p>
      <p><strong>Duration in Fence:</strong> {infoData.durationInFence}</p>
    </div>
  </InfoWindow>
) : null}

        {isPioneer === false ? (
          <>
            {idleList}
            {stopsList2}
            {harshAcceleration}
            {harshTurn}
            {harshBrakes}
            {overSpeeding}
            {/* {geoFenceList} */}
            {/* {geofencesArea} */}
            {ignitionOn}
            {unfastenSeatbelt}
            {enterCellSite}
            {/* {ignitionOff}
      {stopsList}} */}
          </>
        ) : <>
          {/* {geofencesArea} */}
        </>}
        <Marker
          noRedraw={true}
          position={props.path[props.path.length - 1]}
          icon={{
            scale: 0.5,
            url: destination_pin
          }}
        >
        </Marker>
        <Marker
          noRedraw={true}
          position={props.path[0]}
          icon={{
            url: startPoint
          }}
        >
        </Marker>

        {props.geofencesArea && props.geofencesArea[0] != null &&
  props.geofencesArea.map((geofence, index) => {
    if (geofence.geoFenceType === "CIRCLE") {
      // Render a Circle geofence
      return (
        <Circle
          key={index.toString()} // Use a unique key
          center={{
            lat: geofence.center[1], // Latitude
            lng: geofence.center[0], // Longitude
          }}
          radius={geofence.radius} // Radius in meters
          options={{
            strokeColor: "#03ABCE",
            strokeOpacity: 0.8,
            fillColor: "#03ABCE",
            fillOpacity: 0.35,
          }}
          onMouseOver={() => {
            setShowTourInfo(true);
            setInfoData(props?.tourInfo?.[0]); // Access the first element in tourInfo
            setHoveredData({
              latlng: {
                lat: geofence.center[1],
                lng: geofence.center[0],
              },
            }); // Set the hovered position
          }}
          onMouseOut={() => {
            setShowTourInfo(false); // Hide InfoWindow
            setHoveredData(null); // Clear hovered data
          }}
        />
      );
    } else if (geofence.geoFenceType === "POLYGON") {
      // Render a Polygon geofence
      return (
        <Polygon
          key={index.toString()} // Use a unique key
          path={geofence.polygonPath.map(coord => ({
            lat: coord[1], // Latitude
            lng: coord[0], // Longitude
          }))}
          options={{
            strokeColor: "#03ABCE",
            fillColor: "#03ABCE",
            fillOpacity: 0.42,
          }}
        />
      );
    } else {
      return null;
    }
  })}






        {props.position &&
          <Marker
            // angle={props.angle}
            noRedraw={true}
            options={{
              icon: {
                path: window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                scale: 4.5,
                fillColor: '#03ABCE',
                fillOpacity: 10,
                strokeWeight: 0.8,
                rotation: props.angle || 180,
              },
            }}
            position={props.position}
          // icon={pin_moving}
          />

        }
        {/* <MarkerWithLabel
   position={{lat:33.59492064226822,lng:73.13915736973286}}
      labelAnchor={window.google.maps.Point(0, 0)}
      labelStyle={{backgroundColor: "yellow", fontSize: "32px", padding: "16px"}}
    > 
       <div>Hello There!</div>
    </MarkerWithLabel>  */}

      </GoogleMap>
    </div>

  )
})
);