import React, { useState, useEffect, useRef } from "react";
import { withRouter } from 'react-router-dom';
import "./maps.css";
import speedoMeter from '../../../assets/img/ic_speedometer_bubble.png';
import icFeed from '../../../assets/img/ic_feed.png';
import CellTower from "../../../assets/img/tower.png";
// import moment from 'moment';
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import MarkerL from "react-google-maps/lib/components/addons/MarkerWithLabel";
import cold_storage from "../../../assets/img/cold_storage.png";
import battery from "../../../assets/img/battery.png";
import moment from "moment";
import Violation from "./violation";
import iconsList from '../../../static/icons-List.static';
import bike from "../../../assets/img/bike.png";
import car from "../../../assets/img/car.png";
import container from "../../../assets/img/container.png";
import tanker from "../../../assets/img/tanker.png";
import truck from "../../../assets/img/truck.png";
import van from "../../../assets/img/van.png";
import { Spinner } from "../Spinner";
import axios from "axios";

import {
  thingsEndPoints
} from "../../../api/endpoints";


const { compose, withProps, pure, withPropsOnChange, withStateHandlers, withState, withHandlers } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
  Circle,
  Polygon,
  Polyline,
  TrafficLayer,
} = require("react-google-maps");

export const MapWithAMarkerClusterer = compose(
  pure,
  withRouter,
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyD4kLGqTaZAKFG5o5VmF07___IknPJJ5ns&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{
      height: `69vh`,
      background: '#e0e0e0',
      boxShadow: '5px 5px 37px #bcbcbc , -5px -5px 37px #ffffff'
    }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withHandlers(() => {
    const refs = {
      map: undefined,
    }

    return {
      onMapMounted: () => ref => {
        refs.map = ref;
      },
      onBoundsChanged: () => () => {
        let bounds = refs.map.getBounds();
        return bounds
      },
      onCenterChanged: ({ onCenterChanged }) => () => {
        setTimeout(() => {
          onCenterChanged(refs.map)
        }, 10);

      },
    }
  }),
  withScriptjs,
  withGoogleMap
)(React.memo((props) => {
  let companyId = "";
  try {
    const data = JSON.parse(localStorage.getItem("companyInfo"));
    companyId = data.companyId;
  } catch (err) { }
  const prevCountRef = useRef(0);
  const [bounds, setBounds] = useState(null);
  const [markerArray, setMarkerArray] = useState([])
  const [address, setAddress] = useState(null);
  const isMarkerInBounds = (marker, bounds) => {
    var markerLat = marker.latitude.toFixed(14)
    var markerLng = marker.longitude.toFixed(14)
    if (markerLat >= bounds.southWestLat.toFixed(14) &&
      markerLat <= bounds.northEastLat.toFixed(14) &&
      markerLng >= bounds.southWestLng.toFixed(14) &&
      markerLng <= bounds.northEastLng.toFixed(14)) {
      return marker
    }
  }
  const checkBoundsChanged = () => {
    let result = props.onBoundsChanged();
    setBounds(result)
  }
  const getThingsByBounds = () => {
    let markers = props.markers;
    if (bounds) {
      let boundsObj = {}
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();
      boundsObj.southWestLat = sw.lat();
      boundsObj.southWestLng = sw.lng();
      boundsObj.northEastLat = ne.lat();
      boundsObj.northEastLng = ne.lng();
      var filteredMarkers = markers.filter(function (marker) {
        return isMarkerInBounds(marker, boundsObj);
      });
      return filteredMarkers;
    }
  }

  useEffect(() => {
    //prevent re-renders if marker length does not change
    if (bounds !== null && bounds !== undefined ) {
      const filteredMarkers = getThingsByBounds(bounds);
      prevCountRef.current = markerArray.length;
      setMarkerArray(filteredMarkers)
    }
  }, [bounds , props.markers.length]);
  function addViolation(arr, type) {
    const array = arr
      ? arr.map((item, index) => {
        const latlngCords = item.latlngCords;
        const eventTime = moment(item.eventTime).format("LLL");
        const { title } = item;

        return (
          <Violation
            key={index}
            {...{ latlngCords, eventTime, title, type }}
          />
        );
      })
      : [];
    return array;
  }
  function addStop2(arr) {
    const array = arr ? arr.map((item, index) => {
      const latlngCords = item.latlng;

      //   let shape = {
      //     coords: [25, 25, 25],
      //     type: 'circle'
      // }
      var circle = {
        path: window.google.maps.SymbolPath.CIRCLE,
        fillColor: '#662663',
        fillOpacity: 0.5,
        color: '#ffffff',
        // padding:'5px',
        scale: 5,
        strokeColor: '#662663',
        strokeWeight: 7
      };
      // return <Marker
      //   key={index}
      //   position={latlngCords}
      //   label={`${index + 1}`}
      //   // shape={shape}
      //   icon={circle}
      //   zIndex={99999}
      // // labelAnchor={window.google.maps.Point(0, 0)}
      // // labelStyle={{backgroundColor: "red", fontSize: "8px",color:'#fff', padding: "2px"}}
      // >
      //   {/* <div>{(index+1)}</div> */}
      // </Marker>
      const eventTime = moment(item.time).format("LLL");

      return (<Violation key={index} type='stop' {...{ latlngCords, eventTime, duration: item.duration }} />);
    }) : [];
    return array;

  }

  function useInterval(callback, delay) {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      function tick() {
        savedCallback.current();
      }
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  }

  function useMoveMarker(initialValue, { delta = 400, delay = 30 } = {}) {
    let [currentPosition, setCurrentPosition] = useState(initialValue);
    let [currentDeltaPosition, setCurrentDeltaPosition] = useState([0, 0]);
    let [currentDelta, setCurrentDelta] = useState(null);

    useInterval(
      () => {
        setCurrentDelta(++currentDelta);
        setCurrentPosition([
          currentPosition[0] + currentDeltaPosition[0],
          currentPosition[1] + currentDeltaPosition[1]
        ]);
      },
      currentDelta !== null && currentDelta !== delta ? delay : null
    );

    function setPosition(coordinates) {
      const [latitude, longitude] = coordinates;
      setCurrentDelta(0);
      setCurrentDeltaPosition([
        (latitude - currentPosition[0]) / delta,
        (longitude - currentPosition[1]) / delta
      ]);
    }

    return [currentPosition, setPosition, currentDelta];
  }

  function TravellingMarker(lat, lng) {
    let [coordinates, setDestination, currentDelta] = useMoveMarker([lat, lng]);

    useEffect(() => {
      setDestination([lat, lng]);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [lat, lng]);
    return ({ latTravel: coordinates[0], lngTravel: coordinates[1], currentDelta });
  }

  useEffect(() => {
    const head = document.querySelector("head");

    const scriptExists = (src) => {
      return !!document.querySelector(`script[src="${src}"]`);
    };

    const script1Src = "https://cdnjs.cloudflare.com/ajax/libs/marker-animate-unobtrusive/0.2.8/vendor/markerAnimate.js";
    const script2Src = "https://cdnjs.cloudflare.com/ajax/libs/marker-animate-unobtrusive/0.2.8/SlidingMarker.min.js";

    if (!scriptExists(script1Src)) {
      const script1 = document.createElement("script");
      script1.async = true;
      script1.setAttribute("src", script1Src);
      head.appendChild(script1);
    }

    if (!scriptExists(script2Src)) {
      const script2 = document.createElement("script");
      script2.async = true;
      script2.setAttribute("src", script2Src);
      head.appendChild(script2);

      script2.onload = () => {
        if (!scriptExists("data:text/javascript,SlidingMarker.initializeGlobally();")) {
          const script3 = document.createElement("script");
          script3.async = true;
          script3.textContent = "SlidingMarker.initializeGlobally();";
          head.appendChild(script3);
        }
      };
    }
  }, []);


  // const location = useLocation();

  async function fetchAddress(latlng) {
    try {
      const response = await axios.post(thingsEndPoints.fetchAddress(), { latlng: latlng });
      const { data: address } = response.data;
      return JSON.stringify(address);
    } catch (error) {
      console.error('Error fetching address:', error);
    }
  };


  let harshAcceleration = props.events
    ? addViolation(props.events.HARSH_ACCELARATION, "harshAcceleration")
    : null;
  let harshTurn = props.events
    ? addViolation(props.events.HARSH_TURN, "harshTurn")
    : null;
  let harshBrakes = props.events
    ? addViolation(props.events.HARSH_BRAKES, "harshBrakes")
    : null;
  let overSpeeding = props.events
    ? addViolation(props.events.SPEED_LIMIT, "overSpeeding")
    : null;
  // let ignitionOn = props.events
  //   ? addViolation(props.events.IGNITION_ON, "ignitionOn")
  //   : null;
  let stopsList2 = props.stops ? addStop2(props.stops) : null;

  // useEffect(() => {
  //   {
  //     markerArray.map((marker, index) => {
  //       let vehicleStatus = marker?.vehicleStatus;
  //       if (marker?.latitude && marker?.longitude) {
  //         const geocoder = new window.google.maps.Geocoder();
  //         const latlng = {
  //           lat: parseFloat(marker.latitude),
  //           lng: parseFloat(marker.longitude),
  //         };

  //         geocoder.geocode({ location: latlng }, (results, status) => {
  //           if (status === "OK") {
  //             if (results[0]) {
  //               setAddress(results[0].formatted_address);
  //             } else {
  //               console.error("No results found");
  //             }
  //           } else {
  //             console.error("Geocoder failed due to: " + status);
  //           }
  //         });
  //       }
  //     })
  //   }
  // }, [markerArray]);

  return (
    <>

      <GoogleMap
        className="map-shadow"
        ref={props.onMapMounted}
        zoom={props.zoom}
        defaultCenter={{
          lat: props.defaultCenter.lat,
          lng: props.defaultCenter.lng,
        }}
        mapTypeControl={true}
        center={{ lat: props.center.lat, lng: props.center.lng }}
        onTilesLoaded={checkBoundsChanged}
        onDragEnd={props.onCenterChanged}
        options={{
          zoomControl: true,
          // minZoom: 5,
          // maxZoom: 19, 
        }}
      >
        {harshAcceleration}
        {harshTurn}
        {overSpeeding}
        {harshBrakes}
        {stopsList2}
        <MarkerClusterer minimumClusterSize={2} averageCenter ignoreHidden={true} defaultBatchSizeIE={50} batchSizeIE={50} enableRetinaIcons gridSize={50}>
          {/* {props.isTrafficeLayerEnable &&  */}
          <TrafficLayer autoUpdate />

          {/* } */}

          {props.cellSites &&
            props.cellSites.map((cellSite, index) => {
              return (
                <>
                  <Marker
                    noRedraw={true}
                    key={index}
                    position={{
                      lat: parseFloat(cellSite.cords.lat),
                      lng: parseFloat(cellSite.cords.lng),
                    }}
                    options={{
                      icon: CellTower,
                    }}
                  >
                    <InfoWindow
                      options={{
                        backgroundColor: "#000000",
                        disableAutoPan: true,
                      }}
                    >
                      <div className="info-bubble">
                        <span>
                          <span>
                            <span>{props.selectedCellSite.name || "N/A"}</span>
                          </span>
                        </span>
                      </div>
                    </InfoWindow>
                  </Marker>
                </>
              );
            })}
          {/* <TrafficLayer autoUpdate /> */}
          {markerArray.map((marker, index) => {
            let vehicleStatus = marker?.vehicleStatus;
            let arrowColor = "#03ABCE ";
            if (vehicleStatus === "IGNITION_ON") {
              arrowColor = "#0abf60";
            } else if (vehicleStatus === "VEHICLE_MOVING") {
              arrowColor = "#03ABCE";
            } else if (vehicleStatus === "IGNITION_OFF") {
              arrowColor = "#FFA500";
            } else if (
              vehicleStatus === "DEVICE_UNPLUG" ||
              vehicleStatus === "NEVER_HEARD" ||
              vehicleStatus === "NOT_RESPONDING"
            ) {
              arrowColor = "#FF0000";
            } else if (vehicleStatus === "DEVICE_PLUG_IN") {
              arrowColor = "#FFA500";
            }
            else if (vehicleStatus === "IDLE") {
              arrowColor = "#b7b7b7";
            }
            else if (vehicleStatus === "HELMET_IDLE") {
              arrowColor = "#b7b7b7";
            }
            else if (vehicleStatus === "HELMET_NOT_WEARING_DRIVING") {
              arrowColor = "#FF0000";
            }
            else if (vehicleStatus === "HELMET_WEARING_DRIVING") {
              arrowColor = "#03ABCE";
            }
            else if (vehicleStatus === "HELMET_WEARING") {
              arrowColor = "#0abf60";
            }
            else if (vehicleStatus === "HELMET_PARKED") {
              arrowColor = "#FFA500";
            }

            let { latTravel, lngTravel, currentDelta } = props.smoothCall ? TravellingMarker(marker?.latitude, marker?.longitude) : ''
            let smooth = props.smoothCall ? { lat: latTravel, lng: lngTravel } : { lat: marker?.latitude, lng: marker?.longitude };

            return (
              <>
                <Marker
                  key={index}
                  noRedraw={true}
                  position={smooth}
                  onClick={() =>
                    props.onVehicleClick({
                      value: marker?._id,
                      label: marker?.plateNumber,
                    })
                  }
                  options={{
                    icon:
                      props.isColdStorage
                        ? cold_storage
                        : companyId === "603e07546a23817d9e06a2c4"
                          ? battery
                          : {
                            path:
                              window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                            scale: 4.5,
                            fillColor: arrowColor,
                            fillOpacity: 10,
                            strokeWeight: 0.8,
                            rotation: props.smoothCall ? (currentDelta === 200 ? marker?.angle : marker?.oldangle) : marker?.angle || 180,
                          },
                  }}
                >
                  <InfoWindow
                    options={{
                      // "backgroundColor": '#000000',
                      disableAutoPan: true,
                    }}
                  >
                    {
                      !props.isColdStorage &&
                        marker?._id !== "5ffd3987b95af076502de653" ? (
                        <div className="info-bubble">
                          {props.defaultSearchValue && props.defaultSearchValue.value === marker?._id && companyId === "664f4f93e440b860d2c1c93c" ? (

                            <span>
                              <span>
                                <span>Plate Number :</span>
                                <span>{marker?.plateNumber || "N/A"}</span>
                              </span>
                              <br />
                              <span>
                                <span>Speed: </span>
                                <span>{marker?.speed || "0"}</span>
                              </span>
                              <br />
                              <span>
                                <span>Angle: </span>
                                <span>{marker?.angle || "0"}</span>
                              </span>
                              <br />
                              <span>
                                <span>Device Latitude: </span>
                                <span>{marker?.latitude || "N/A"}</span>
                              </span>
                              <br />
                              <span>
                                <span>Device Longitude: </span>
                                <span>{marker?.longitude || "N/A"}</span>
                              </span>
                              <br />

                              <span>
                                <span>Location: </span>
                                <span>{address || "Fetching address..."}</span>
                              </span>
                              <br />
                              <span>
                                <span>Last Update: </span>
                                <span>{marker?.lastObservationTime ? moment(marker?.lastObservationTime).format('DD/MM/YYYY, h:mm a') : "N/A"}</span>
                              </span>
                              <br />
                              {/* <span>
  <span>Last Location:</span>
  <span>{ fetchAddress(marker?.lastLocation) || "N/A"}</span>
</span>
<br /> */}
                            </span>

                          ) : props.defaultSearchValue && props.defaultSearchValue.value === marker?._id ? (
                            <span className="info-map">
                              <span><img alt="sp" src={icFeed} />&nbsp;&nbsp;</span>
                              <span>{marker?.plateNumber || "N/A"} </span>&nbsp;|&nbsp;
                              <span >
                                <span><img alt="sp" src={speedoMeter} /> </span>
                                <span>&nbsp;{marker?.speed || "0"}</span>
                              </span>
                            </span>
                          ) : props.location.pathname === "/vehicles/trips" ? (
                            <span>
                            <span>
                              <span>Plate Number :</span>
                              <span>{marker?.plateNumber || "N/A"}</span>
                            </span>
                            <br />
                            <span>
                              <span>Speed: </span>
                              <span>{marker?.speed || "0"}</span>
                            </span>
                            <br />
                            <span>
                              <span>Angle: </span>
                              <span>{marker?.angle || "0"}</span>
                            </span>
                            <br />
                            <span>
                              <span>Device Latitude: </span>
                              <span>{marker?.latitude || "N/A"}</span>
                            </span>
                            <br />
                            <span>
                              <span>Device Longitude: </span>
                              <span>{marker?.longitude || "N/A"}</span>
                            </span>
                            <br />

                            <span>
                              <span>Location: </span>
                              <span>{address || "Fetching address..."}</span>
                            </span>
                            <br />
                            <span>
                              <span>Last Update: </span>
                              <span>{marker?.lastObservationTime ? moment(marker?.lastObservationTime).format('DD/MM/YYYY, h:mm a') : "N/A"}</span>
                            </span>
                            <br />
                            {/* <span>
<span>Last Location:</span>
<span>{ fetchAddress(marker?.lastLocation) || "N/A"}</span>
</span>
<br /> */}
                          </span>
                          ) : (
                            <span className="info-map">
                              <span><img alt="sp" src={icFeed} />&nbsp;&nbsp;</span>
                              <span>{marker?.plateNumber || "N/A"} </span>&nbsp;|&nbsp;
                              <span >
                                <span><img alt="sp" src={speedoMeter} /> </span>
                                <span>&nbsp;{marker?.speed || "0"}</span>
                              </span>
                            </span>
                          )}
                        </div>

                      ) : (
                        <div className="info-bubble">
                          <span className="info-map">
                            <span>{marker?.plateNumber || "N/A"} </span> &nbsp;
                            {marker?.vehicleMakeAndModel || ""}
                          </span>
                        </div>
                      )}
                  </InfoWindow>
                </Marker>
              </>
            );
          })}
        </MarkerClusterer>
        {props.showGeofence &&
          props.geofence.map((circle, index) => {
            if (circle.geoFenceType === "CIRCLE") {
              return (
                <Circle
                  center={{
                    lat: circle.center[1],
                    lng: circle.center[0],
                  }}
                  radius={circle.radius}
                  key={index.toString()}
                  options={{
                    strokeColor: "#03ABCE",
                    strokeOpacity: 0.8,
                    fillColor: "#03ABCE",
                    fillOpacity: 0.35,
                  }}
                />
              );
            }
          })}

        {props.showGeofence &&
          props.geofence.map((polygon, index) => {
            if (polygon.geoFenceType === "POLYGON") {
              return (
                <Polygon
                  key={index.toString()}
                  options={{
                    strokeColor: "#03ABCE",
                    fillColor: "#03ABCE",
                    fillOpacity: 0.42
                  }}
                  center={{
                    lat: polygon.center[1],
                    lng: polygon.center[0],
                  }}
                  path={polygon.polygonPath}
                />
              );
            }
          })}

        {props.showRoutefence &&
          props.routefence.map((polygon, index) => {
            const paths = polygon.path.map((path) => {
              return { lat: path[1], lng: path[0] };
            });
            return <Polygon paths={paths} key={index.toString()} />;
          })}

        <Polyline
          paths={props.track}
          options={{
            path: props.track,
          }}
        />

        {props.trackFootprints && <Polyline
          paths={props.tracks}
          options={{
            path: props.tracks,
          }}
        />}
      </GoogleMap>
    </>
  );
}))
function areEqual(prevProps, nextProps) {
  if (prevProps.center.lat === nextProps.center.lat
    && prevProps.center.lng === nextProps.center.lng
    && prevProps.defaultCenter.lat === nextProps.defaultCenter.lat
    && prevProps.defaultCenter.lng === nextProps.defaultCenter.lng) {
    return true
  }
}
