import axios from "axios";
import {
  trikesSummary, thingsEndPoints,
  groupEndPoints,
  userEndpoints,
  reportsEndPoints,
} from "../api/endpoints";
import Duck from "extensible-duck";
import generateExcelReport from "../utils/generateReport";
import trikesIcon from "../assets/img/trike.png"
import operationalTrikeIcon from "../assets/img/operational.png";
import staticTrikesIcon from "../assets/img/static.png";
import averageDailyDistanceIcon from "../assets/img/dailyTravelDistance.png"
import averageMonthlyDistanceIcon from "../assets/img/monthlyTravelDistance.png"
import averageWeeklyDistanceIcon from "../assets/img/weeklyTravelDistance.png"

import averageDailyTravelTime from "../assets/img/dailyTravelTime.png"
import averageWeeklyTravelTime from "../assets/img/weeklyTravelTime.png"
import averageMonthlyTravelTime from "../assets/img/monthlyTravelTime.png"
import idleTimeIcon from "../assets/img/idle.png"
import topFiveIcon from '../assets/img/topMSM.png';


const mapMergedArray = (array) =>
  array.map(obj => [
    {
      title: 'Number Of Trikes',
      value: obj?.numberOfTrikes?.toString() || "0",
      icon: trikesIcon
    },
    {
      title: 'Status',
      value: (obj?.operationalTrikeCount || obj?.staticTrikeCount)
        && { operationalTrike: obj?.operationalTrikeCount.toString(), staticTrike: obj?.staticTrikeCount.toString() },
      icon: obj.operationalTrikeCount ? operationalTrikeIcon : staticTrikesIcon
    },
    {
      title: 'Idle Time',
      value: obj?.idleTime + "\n" + "hh:mm:ss" || "00:00:00",
      icon: idleTimeIcon
    },
    {
      title: 'Average Daily Distance Per Trike',
      value: obj?.averageDailyDistance + " KM" || 0,
      icon: averageDailyDistanceIcon
    },
    {
      title: 'Average Weekly Distance Per Trike',
      value: obj?.averageWeeklyDistance + " KM" || 0,
      icon: averageWeeklyDistanceIcon
    },
    {
      title: 'Average Monthly Distance Per Trike',
      value: obj?.averageMonthlyDistance + " KM" || 0,
      icon: averageMonthlyDistanceIcon
    },
    {
      title: 'Average Daily Travel Time Per Trike',
      value: obj?.averageDailyTravelTime + "\n hh:mm:ss" || "00:00:00",
      icon: averageDailyTravelTime
    },
    {
      title: 'Average Weekly Travel Time Per Trike',
      value: obj?.averageWeeklyTravelTime + "\n hh:mm:ss" || "00:00:00",
      icon: averageWeeklyTravelTime
    },
    {
      title: 'Average Monthly Travel Time Per Trike',
      value: obj?.averageMonthlyTravelTime + "\n hh:mm:ss" || "00:00:00",
      icon: averageMonthlyTravelTime
    },
    {
      title: 'Name of MSM',
      value: obj?.topFive || "N/A",
      icon: topFiveIcon
    },

    {
      title: 'Trikes Status',
      value: (obj?.productiveTrikeCount || obj?.nonProductiveTrikeCount)
        && { productiveTrikeCount: obj?.productiveTrikeCount.toString(), nonProductiveTrikeCount: obj?.nonProductiveTrikeCount.toString() },
      icon: obj.productiveTrikeCount ? operationalTrikeIcon : staticTrikesIcon
    },
    
  ]);

const mapMergedArrayForReport = (array) =>
  array.map((obj) => ({
    'Number Of Trikes': obj?.numberOfTrikes?.toString() || "0",
    'Operational Trikes': obj?.operationalTrikeCount || "0",
    'Static trikes': obj?.staticTrikeCount || "0",
    'Idle Time': obj?.idleTime || "00:00:00",
    'Average Daily Distance': obj?.averageDailyDistance + " KM" || "0 KM",
    'Average Weekly Distance': obj?.averageWeeklyDistance + " KM" || "0 KM",
    'Average Monthly Distance': obj?.averageMonthlyDistance + " KM" || "0 KM",
    'Average Daily Travel Time': obj?.averageDailyTravelTime || "00:00:00",
    'Average Weekly Travel Time': obj?.averageWeeklyTravelTime || "00:00:00",
    'Average Monthly Travel Time': obj?.averageMonthlyTravelTime || "00:00:00",
  }));


  const mapMergedArrayForStatusReport = (array) =>
  array.map((obj) => ({
    'Date': obj?.date?.toString() || "00",
    'Trike Name': obj?.trikeName || "N/A",
    'Plate Number': obj?.plateNumber || "N/A",
    'Productive Status': obj?.productive ? "Yes": "No",
  }));
  




export default new Duck({
  namespace: "Trikes",
  store: "trikes",
  types: ["IS_LOADING", "GET_TRIKES_SCORE", "GET_TOTAL_SCORE", "GET_THINGS", "GET_GROUPS", "GET_VIOLATIONS", "THING_IS_LOADING", "VEHICLE_DETAILS", "PLATE_NUMBERS_FETCHED", "GET_THINGS_LIST"],
  initialState: {
    isLoading: false,
    analytics: null,
    accumulativeAnalytics: null,
    things: null,
    groupList: null,
    trikesList: null,
    cardinalViolations: null,
    data: null,
    vehiclesPlateNumber: [],
  },
  reducer: (state, action, duck) => {
    switch (action.type) {
      case duck.types.IS_LOADING:
        return { ...state, isLoading: action.isLoading };
      case duck.types.GET_TRIKES_SCORE:
        return {
          ...state,
          analytics: action.analytics,
          data: action.data || null,
        };
      case duck.types.GET_TOTAL_SCORE:
        return { ...state, data: action.data };
      case duck.types.GET_THINGS:
        return { ...state, things: action.things };
        case duck.types.GET_THINGS_LIST:
          return { ...state, trikesList: action.trikesList };
      case duck.types.GET_GROUPS:
        return { ...state, groupList: action.groupList };
      case duck.types.PLATE_NUMBERS_FETCHED:
        return {
          ...state,
          vehiclesPlateNumber: action.vehiclesPlateNumber,
        };
      default:
        return state;
    }
  },
  selectors: {
    root: (state) => state,
  },
  creators: (duck) => ({
    getSummary: (id, data) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const response = await axios.post(trikesSummary.getSummary, { id, data });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });
        const res = response.data.data;
        if (res && res.length) {
          console.log("hellloooooo -> m", res)
          const formattedArray = mapMergedArray(res);


          dispatch({ type: duck.types.GET_TRIKES_SCORE, data: formattedArray });
        } else {
          dispatch({ type: duck.types.GET_TRIKES_SCORE, data: response.data.data[0] });
        }
      } catch (error) {
        console.error("Error fetching summary data:", error);
      }
    },

    getSummaryAndDownloadReport: (id, data) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const response = await axios.post(trikesSummary.getSummary, { id, data });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });

        let res = response.data.data
          ? mapMergedArrayForReport(response.data.data)
          : [response.data.data[0]];

        const startDate = data?.selection?.startDate;
        const endDate = data?.selection?.endDate;
        console.log("startDate ----- ", startDate)
        console.log("endDate ----- ", endDate)
        const incrementedStartDate = new Date(startDate);
        incrementedStartDate.setDate(incrementedStartDate.getDate() + 1);;

        generateExcelReport(
          res,
          "trikes_report",
          incrementedStartDate.toISOString().split('T')[0],
          endDate.toISOString().split('T')[0],
          startDate?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
          endDate?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
          ["Number Of Trikes", "Operational Trikes", "Static Trikes", "Idle Time (hh:mm:ss)", "Average Daily Distance", "Average Weekly Distance", "Average Monthly Distance", "Average Daily Travel Time (hh:mm:ss)", "Average Weekly Travel Time (hh:mm:ss)", "Average Monthly Travel Time (hh:mm:ss)"]
        );
      } catch (error) {
        console.error("Error generating report:", error);
      }
    },


    getStatusAndDownloadReport: (id, data) => async (dispatch) => {
      try {
        dispatch({ type: duck.types.IS_LOADING, isLoading: true });
        const response = await axios.post(trikesSummary.getStatus, { id, data });
        dispatch({ type: duck.types.IS_LOADING, isLoading: false });

        let res = response.data.data
          ? mapMergedArrayForStatusReport(response.data.data)
          : [response.data.data[0]];

        const startDate = data?.selection?.startDate;
        const endDate = data?.selection?.endDate;
        console.log("startDate ----- ", startDate)
        console.log("endDate ----- ", endDate)
        const incrementedStartDate = new Date(startDate);
        incrementedStartDate.setDate(incrementedStartDate.getDate() + 1);;

        generateExcelReport(
          res,
          "trikes_status_report",
          incrementedStartDate.toISOString().split('T')[0],
          endDate.toISOString().split('T')[0],
          startDate?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
          endDate?.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }),
          ["Date", "Trike Name", "Plate Number", "Productivity Status"]
        );
      } catch (error) {
        console.error("Error generating report:", error);
      }
    },

    getThings: () => async (dispatch) => {
      try {
        const response = await axios.get(trikesSummary.getThings);
        //dispatch({ type: duck.types.GET_THINGS, things: response.data.data });

         dispatch({ type: duck.types.GET_THINGS_LIST, trikesList: response.data.data });
      } catch (error) {
        console.error("Error fetching things:", error);
      }
    },

    getVehiclesPlateNumber: () => async (dispatch) => {
      try {
        let companyInfo = JSON.parse(localStorage.getItem("companyInfo"));


        console.log("calllll123 companyInfo ", companyInfo)

        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: true });
        const response = await axios.get(
          thingsEndPoints.groupPlateNumber(companyInfo.groupId)
        );

        console.log("calllll123 response ", response)
        // console.log('group plate number');
        // console.log(response.data.data)
        const vehiclesPlateNumber = response.data.data;

        dispatch({
          type: duck.types.PLATE_NUMBERS_FETCHED,
          vehiclesPlateNumber,
        });
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        return vehiclesPlateNumber;
      } catch (e) {
        dispatch({ type: duck.types.THING_IS_LOADING, isLoading: false });
        throw e;
      }
    },

    clearVehicleDetails: () => async (dispatch) => {
      try {
        const vehicleDetail = {};

        dispatch({ type: duck.types.VEHICLE_DETAILS, vehicleDetail });
      } catch (e) { }
    },

    GetVehicleDetails: (state) => async (dispatch, getState) => {
      try {
        const response = await axios.get(
          thingsEndPoints.getVehicleDetails(state.id)
        );

        const vehicleDetail = response.data.data;

        dispatch({ type: duck.types.VEHICLE_DETAILS, vehicleDetail });
      } catch (e) {
        // throw e;
      }
    },

    getTrackPoints: (data, id) => async () => {
      try {
        const response = await axios.get(trikesSummary.getTrackPoints(id), {
          params: { data },
        });
        return response.data.data;
      } catch (error) { }
    },

    getSubGroups: () => async (dispatch) => {
      try {
        const response = await axios.get(trikesSummary.getGroups);
        dispatch({ type: duck.types.GET_GROUPS, groupList: response.data.data });
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },
  }),
});
